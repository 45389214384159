import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '@/components/NotFound.vue';

// Define the base URL as a regular string
const url = 'https://neatfreaks.co.ke/';

const routes = [
  { 
    path: '/', 
    component: () => import('@/components/HomePage.vue'), //  lazy loading
    meta: {
      title: 'Home - Neatfreaks Cleaning Solutions',
      canonical: `${url}home`,  
    },
  },
  {
    path: '/services',
    component: () => import('@/components/ServicePage.vue'), //  lazy loading
    meta: {
      title: 'Our services - Neatfreaks Cleaning Solutions',
      canonical: `${url}services`, 
    },
  },
  {
    path: '/contact-us',
    component: () => import('@/components/ContactusPage.vue'), //  lazy loading
    meta: {
      title: 'Contact us - Neatfreaks Cleaning Solutions',
      canonical: `${url}contact-us`,  
    },
  },
    // Catch-all route for 404 Not Found pages
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() { 
    // Always scroll to top when navigating to a new page
    return { top: 0 };
  },
});

export default router;
