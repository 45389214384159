<template>
    <div class="social-buttons">
<!-- Email link -->
<a href="mailto:info@neatfreaks.co.ke" class="social-button google" rel="noopener noreferrer" aria-label="Send an email to Neatfreaks">
  <i class="fab fa-google"></i>
</a>

<!-- Phone link -->
<a href="tel:+254799472096" class="social-button call" rel="noopener noreferrer" aria-label="Call Neatfreaks at +254799472096">
  <i class="fas fa-phone"></i>
</a>

<!-- WhatsApp link -->
<a href="https://wa.me/254799472096?text=https://projects.campussoko.co.ke/neatfreaks" class="social-button whatsapp"
 rel="noopener noreferrer" target="_blank" aria-label="Chat with Neatfreaks on WhatsApp">
  <i class="fab fa-whatsapp"></i>
</a>


    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  .social-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    color: #000000;
    text-decoration: none;
    font-size: 24px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .social-button:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .facebook {
    background-color: #3b5998;
  }
  
  .twitter {
    background-color: #1da1f2;
  }
  
  .instagram {
    background-color: #e1306c;
  }
  
  .whatsapp {
    background-color: #25d366;
  }
  </style>
  