<template>
  <nav class="navbar" role="navigation" aria-label="Main navigation">
    <router-link to="/" class="logo">
  <img
    src="@/assets/img/logo-icon-transparent.png"
    alt=""
    aria-hidden="true"
  />
  <h1>NeatFreaks</h1>
</router-link>
    <div
      class="menu-icon"
      @click="toggleMenu"
      @keydown.enter="toggleMenu"
      @keydown.space="toggleMenu"
      aria-label="Toggle navigation menu"
      :aria-expanded="isMenuActive.toString()"
      role="button"
      tabindex="0"
    >
      <i
        :class="isMenuActive ? 'fas fa-times' : 'fas fa-bars'"
        aria-hidden="true"
      ></i>
    </div>
    <ul :class="['nav-links', { 'nav-active': isMenuActive }]" role="menu">
      <li role="none">
        <router-link to="/" @click="handleLinkClick" role="menuitem"
          >HOME</router-link
        >
      </li>
      <li role="none">
        <router-link to="services" @click="handleLinkClick" role="menuitem"
          >OUR SERVICES</router-link
        >
      </li>
      <li role="none">
        <router-link to="contact-us" @click="handleLinkClick" role="menuitem"
          >CONTACT US</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MainNavbar",
  data() {
    return {
      isMenuActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    handleLinkClick() {
      if (this.isMenuActive) {
        this.toggleMenu();
      }
    },
  },
};
</script>

<style scoped>
/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #008bca;
  padding: 5px 5px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

/* Logo section */
.logo {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  text-decoration: none;
}

.logo img {
  height: 30px;
  margin-right: 5px;
  border-radius: 50px;
  text-align: none;
}

.logo h1 {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  font-weight: bolder;
}

.logo:hover {
  border: 0.5px solid #fff;
}

/* Menu icon (visible on mobile) */
.menu-icon {
  display: none;
  cursor: pointer;
  padding-right: 30px;
}

.menu-icon i {
  color: #fff;
  font-size: 24px;
}

/* Navigation links */
.nav-links {
  display: flex;
  list-style: none;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #051827;
  padding-top: 60px;
  z-index: 1000;
}

.nav-links.nav-active {
  transform: translateX(0);
}

.nav-links li {
  margin: 1px 0;

}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px;
  display: block;
}

.nav-links a:hover {
  border: 0.5px solid #fff;
  font-weight: bolder;
}

/* Focus styles for accessibility */
a:focus {
  outline: 2px solid #42b983;
  outline-offset: 2px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 769px) {
  .nav-links {
    display: flex;
    transform: none;
    position: static;
    height: auto;
    width: auto;
    padding-top: 0;
    background-color: transparent;
  }

  .menu-icon {
    display: none;
  }
}
</style>
