import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/css/main.css';
import Swal from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { createMetaManager } from 'vue-meta';
import VueLazyload from 'vue-lazyload';

// Create the Vue app instance
const app = createApp(App);

// Properly initialize vue-meta
const metaManager = createMetaManager();  // Execute the function to create the manager

// Use the necessary plugins
app.use(router);
app.use(Swal);
app.use(metaManager);  // Now correctly passing the meta manager instance
app.use(VueLazyload);

app.mount('#app');