<template>
  <div id="app"> <!-- Ensure this is the actual root element ID -->
    <MainNavbar />
    <div class="page-main-content">
      <MainNavbar/>
      <router-view :key="currentRouteKey"></router-view>
      <FooterSection/>
      <SocialButtons/>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import MainNavbar from './components/MainNavbar.vue';
import FooterSection from './components/FooterSection.vue';
import SocialButtons from './components/SocialButtons.vue';

const currentRouteKey = computed(() => {
      // Use the current route path as the key
      return window.location.pathname; // or you can use `this.$route.path` if you're using Options API
    });

</script>

<style scoped>
/* Reset body margins */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/* Make sure the app container fills the entire viewport */
#app {
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/img/hero-bg-img.jpeg');
  background-size: cover;
  background-attachment: fixed; 
  background-position: center;
}
/* Main content takes the remaining space */
.page-main-content {
  margin-top: 75px; /* Adjust this value to be the height of your navbar */
  overflow: auto; /* Enable scrolling if content overflows */
  flex: 1;
}
/* Optional: ensure content sections don't overflow their container */
.HeroSection, .HomePage, .SectionPart, .SocialButtons {
  box-sizing: border-box;
}
</style>
